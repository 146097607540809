import { api } from ":mods";
import { ENDPOINTS } from "../const";
import { OTPProps } from "../models";

export async function verifyEmailOtp(data: OTPProps) {
  return api
    .patch(ENDPOINTS.verify_email_otp, data)
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
